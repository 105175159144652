import { styled } from 'components/src/components/Layout/ThemeProvider/ThemeProvider';
import * as React from 'react';
import { Header } from '../components/Header';
import { Img } from '../components/Img';
import { Wrapper } from '../components/Wrapper';

const imageSource = require('./../components/assets/undraw_my_documents_ym8x.png');

const Warning = styled.div`
  font-size: 2rem;
  border: 5px solid #40a70b;
  border-radius: 10rem;
  padding: 3rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

const IndexPage = () => {

  return (
    <Wrapper>
      <Header>
        <span className="number">4.</span>
        Udało się!
      </Header>
      <Warning>
        Twój plik powinien pojawić się na liście pobierania w przeglądarce.
      </Warning>
      <Img src={imageSource}/>
    </Wrapper>
  );
};

export default IndexPage;
